<template>
  <div class="biddingSchedule">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>
    <v-dialog
      v-model="resultDialog"
      width="600"
      v-if="biddingResults.length != 0"
    >
      <v-card
        class="mx-auto"
        max-width="1000"
        outlined
        v-for="(bidding, index) in biddingResults"
        :key="index"
      >
        <v-list-item four-line>
          <v-list-item-content>
            <div class="overline mb-4">
              BIDDING RESULT :
              <span style="color: green; font-weight: bold">{{
                bidding.result
              }}</span>
            </div>

            <v-list-item-title class="headline mb-1">{{
              bidding.productTitle
            }}</v-list-item-title>
            <v-list-item-subtitle
              >Expected Price : ${{ bidding.expectPrice }}</v-list-item-subtitle
            >
            <div class="overline mb-4">
              Highest bidded price :
              <span style="color: red; font-weight: bold; font-size: 15px"
                >${{ bidding.latestBidPrice }}</span
              >
            </div>
          </v-list-item-content>

          <v-list-item-avatar tile size="100" color="grey">
            <img :src="bidding.carDefaultImg" />
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item three-line>
          <v-row>
            <v-col cols="6">
              <h4 style="text-decoration: underline">Seller Info:</h4>
              <br />
              <h4 style="padding-left:20px">
                Account Type: {{ bidding.sellerDetail.accType }}
              </h4>
              <h4 style="padding-left:20px">
                Name: {{ bidding.sellerDetail.name }}
              </h4>
              <h4 style="padding-left:20px">
                Email: {{ bidding.sellerDetail.email }}
              </h4>
              <h4 style="padding-left:20px">
                Phone: {{ bidding.sellerDetail.phone }}
              </h4>
              <h4 style="padding-left:20px">
                Dealer Name: {{ bidding.sellerDetail.dealerName }}
              </h4>
              <h4 style="padding-left:20px">
                Dealer Phone: {{ bidding.sellerDetail.dealerPhone }}
              </h4>
            </v-col>

            <v-col cols="6">
              <h4 style="text-decoration: underline">Bidder Info:</h4>
              <br />
              <h4 style="padding-left:20px">
                Account Type: {{ bidding.latestBidderDetail.accType }}
              </h4>
              <h4 style="padding-left:20px">
                Name: {{ bidding.latestBidderDetail.name }}
              </h4>
              <h4 style="padding-left:20px">
                Email: {{ bidding.latestBidderDetail.email }}
              </h4>
              <h4 style="padding-left:20px">
                Phone: {{ bidding.latestBidderDetail.phone }}
              </h4>
              <h4 style="padding-left:20px">
                Dealer Name: {{ bidding.latestBidderDetail.dealerName }}
              </h4>
              <h4 style="padding-left:20px">
                Dealer Phone: {{ bidding.latestBidderDetail.dealerPhone }}
              </h4>
              <br />
            </v-col>
          </v-row>
        </v-list-item>
      </v-card>
    </v-dialog>

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this bidding schedule:
          <b>{{ delBiddingSchedule.id }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelBiddingSchedule()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add car dialog box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Bidding Schedule
        </v-card-title>
        <v-card-text>
          <div style="margin-top: 16px">
            Bid Start Time:
          </div>
          <v-datetime-picker
            format="DATETIME_SHORT"
            v-model="addBiddingSchedule.bidStartTime"
          >
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>

          <div style="margin-top: 16px">
            Bid End Time:
          </div>
          <v-datetime-picker
            format="DATETIME_SHORT"
            v-model="addBiddingSchedule.bidEndTime"
          >
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>
          <!-- <v-text-field
            v-model="addBiddingSchedule.demand"
            label="Demand"
          ></v-text-field>
          <v-text-field
            v-model="addBiddingSchedule.maxAllowed"
            label="Max Allowed"
          ></v-text-field>
          <v-text-field
            v-model="addBiddingSchedule.minAllowed"
            label="Min Allowed"
          ></v-text-field> -->
          <v-textarea
            v-model="addBiddingSchedule.sysRemarks"
            label="Sys Remarks"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddBiddingSchedule()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Bidding Schedule
        </v-card-title>
        <v-card-text>
          <div style="margin-top: 16px">
            Bid Start Time:
          </div>
          <v-datetime-picker v-model="editBiddingSchedule.bidStartTime">
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>

          <div style="margin-top: 16px">
            Bid End Time:
          </div>
          <v-datetime-picker v-model="editBiddingSchedule.bidEndTime">
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>
          <!-- <v-text-field
            v-model="editBiddingSchedule.demand"
            label="Demand"
          ></v-text-field>
          <v-text-field
            v-model="editBiddingSchedule.maxAllowed"
            label="Max Allowed"
          ></v-text-field>
          <v-text-field
            v-model="editBiddingSchedule.minAllowed"
            label="Min Allowed"
          ></v-text-field> -->
          <v-textarea
            v-model="editBiddingSchedule.sysRemarks"
            label="Sys Remarks"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditBiddingSchedule()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search box -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="nameSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="dialog = !dialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>

    <!-- pagination table -->
    <v-data-table
      :headers="headers"
      :items="biddingSchedule"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.bidStartTime }}</td>
          <td>{{ row.item.bidEndTime }}</td>
          <td>{{ row.item.productTitles[0] }}</td>
          <!-- <td>{{ row.item.demand }}</td>
          <td>{{ row.item.maxAllowed }}</td>
          <td>{{ row.item.minAllowed }}</td> -->
          <td>{{ row.item.status }}</td>
          <td>{{ row.item.sysRemarks }}</td>
          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="orange"
              @click="resultButtonClick(row.item)"
            >
              <v-icon>mdi-trophy</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import DatetimePicker from "vuetify-datetime-picker";

import postAxios from "../../../services/axios-post";
import putAxios from "../../../services/axios-put";
import getAxios from "../../../services/axios-get";
import deleteAxios from "../../../services/axios-delete";
Vue.use(DatetimePicker);
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      addBiddingSchedule: {
        bidEndTime: new Date(),
        bidStartTime: new Date(),
        demand: null,
        maxAllowed: null,
        minAllowed: null,
        sysRemarks: null,
      },
      editBiddingSchedule: {
        id: null,
        bidEndTime: new Date(),
        bidStartTime: new Date(),
        demand: null,
        maxAllowed: null,
        minAllowed: null,
        sysRemarks: null,
      },
      biddingResults: [],
      biddingResult: {
        biddingId: null,
        carDefaultImg: null,
        expectPrice: 0,
        latestBidPrice: 0,
        productTitle: null,
        result: null,
        latestBidderDetail: {
          accType: null,
          dealerId: null,
          dealerName: null,
          dealerPhone: null,
          email: null,
          name: null,
          phone: null,
        },
        sellerDetail: {
          accType: null,
          dealerId: null,
          dealerName: null,
          dealerPhone: null,
          email: null,
          name: null,
          phone: null,
        },
      },
      delBiddingSchedule: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      resultDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Bid Start Time", value: "bidStartTime" },
        { text: "Bid End Time", value: "bidEndTime" },
        { text: "Car Title", value: "productTitles" },
        { text: "Status", value: "status" },
        { text: "Sys Remarks", value: "sysRemarks" },
        { text: "Edit" },
        { text: "Delete" },
        { text: "Result" },
      ],
      biddingSchedule: [],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchBiddingSchedule();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchBiddingSchedule();
    },
    delButtonClick(item) {
      this.delBiddingSchedule.id = item.id;
      this.delBiddingSchedule.name = item.name;
      this.deleteDialog = true;
    },
    resultButtonClick(item) {
      this.delBiddingSchedule.id = item.id;

      const self = this;
      const params = {
        biddingScheduleId: item.id,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/generateBiddingReport`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            var arr = res.data.data;
            self.biddingResults = arr;
          }
        })
        .catch(function(error) {
          self.biddingResults = [];
          console.log(error.response.data);
          self.showAlert("error", error.response.data.message);
        });
      this.resultDialog = true;
    },
    editButtonClick(item) {
      console.log(item.bidStartTime);
      this.editBiddingSchedule.id = item.id;
      this.editBiddingSchedule.bidEndTime = new Date(item.bidEndTime);
      this.editBiddingSchedule.bidStartTime = new Date(item.bidStartTime);
      this.editBiddingSchedule.demand = item.demand;
      this.editBiddingSchedule.maxAllowed = item.maxAllowed;
      this.editBiddingSchedule.minAllowed = item.minAllowed;
      this.editBiddingSchedule.sysRemarks = item.sysRemarks;
      this.editDialog = true;
    },
    doDelBiddingSchedule() {
      const self = this;
      const param = {
        biddingScheduleId: self.delBiddingSchedule.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/delBiddingSchedule`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchBiddingSchedule();
            self.showAlert("success", "Bidding Schedule Deleted Successfully!");
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    async doEditBiddingSchedule() {
      console.log("bid start time : " + this.bidStartTime);
      console.log("bid start time : " + this.editBiddingSchedule.bidStartTime);

      console.log("bid end time : " + this.bidEndTime);
      console.log("bid end time : " + this.editBiddingSchedule.bidEndTime);
      var self = this;
      try {
        const params = {
          id: self.editBiddingSchedule.id,
          bidEndTime: self.editBiddingSchedule.bidEndTime,
          bidStartTime: self.editBiddingSchedule.bidStartTime,
          demand: self.editBiddingSchedule.demand,
          maxAllowed: self.editBiddingSchedule.maxAllowed,
          minAllowed: self.editBiddingSchedule.minAllowed,
          sysRemarks: self.editBiddingSchedule.sysRemarks,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBiddingSchedule`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editDialog = false;
              self.fetchBiddingSchedule();
              self.showAlert(
                "success",
                "Bidding Schedule Edited Successfully!"
              );
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    async doAddBiddingSchedule() {
      const self = this;
      try {
        const params = {
          bidEndTime: this.addBiddingSchedule.bidEndTime,
          bidStartTime: this.addBiddingSchedule.bidStartTime,
          demand: this.addBiddingSchedule.demand,
          maxAllowed: this.addBiddingSchedule.maxAllowed,
          minAllowed: this.addBiddingSchedule.minAllowed,
          sysRemarks: this.addBiddingSchedule.sysRemarks,
        };
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/addBiddingSchedule`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.dialog = false;
              self.fetchBiddingSchedule();
              self.showAlert("success", "New Bidding SChedule Added!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    chooseImage() {
      this.addBiddingSchedule.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchType() {
      this.fetchBiddingSchedule();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchBiddingSchedule() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/biddingSchedule`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.biddingSchedule = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
          self.biddingSchedule.forEach(x => {
            x.bidStartTime = this.formatDate(x.bidStartTime);
            x.bidEndTime = this.formatDate(x.bidEndTime);
          });
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    formatDate(datex) {
      let date = new Date(datex);
      let formatted_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes();
      return formatted_date;
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
